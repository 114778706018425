.wrap-loading-page {
  .wrap-bottom-navigation {
    z-index: 102;
    height: unset;
    .nav-menu {
      ul {
        padding-top: 0px;
        padding-bottom: 10px;
      }
    }
  }
}

#loading-screen {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  opacity: 1;
  transition: 1s opacity;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;

  #loading-cube-wrapper {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  #wrap-cube-model {
    position: absolute;
    top: 29px;
    z-index: 1;

    &.disable-click {
      pointer-events: none;
    }
  }

  .slick-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 0;
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
      }
    }
    .slide {
      height: 100vh;
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .wrap-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    &.center-center {
      img {
        object-fit: cover;
        width: 100%;
      }
    }

    img {
      height: 90vh;
      @media only screen and (max-width: 1366px) {
        height: 80vh;
      }
    }
  }

  .wrap-intro {
    &.hide {
      display: none;
    }

    &.show {
      display: unset;
    }
  }

  &.hide {
    display: none;
  }

  .intro-content {
    z-index: 1;
  }

  .wrap-slide-loading {
    transition: all 1s;

    .wrap-content {
      position: absolute;
      bottom: 50px;
      left: 50px;
      text-align: left;

      .wrap-line {
        display: flex;
        border-top: 1px solid #fff;

        .left,
        .right {
          padding: 10px;
        }

        .left {
          width: 75%;
          border-right: 1px solid #fff;
        }

        .right {
          width: 25%;
          // letter-spacing: 0.2rem;
        }
      }
    }

    &.hide {
      opacity: 0;
      pointer-events: none;
    }

    &.show {
      opacity: 1;
      pointer-events: unset;
    }
  }
}

#intro-video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#intro-img {
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: -1;
}

.intro-content {
  // margin-top: 8vh;
  line-height: 1;

  .wrap-button {
    height: 102px;
  }

  h1 {
    font-size: calc(3rem + 1.25vw);
    font-family: 'Philosopher', serif;
    margin: 2rem 0 1.75rem;
  }

  p {
    font-size: calc(0.875rem + 0.25vw);
    font-weight: 400;
    margin-bottom: 1.5rem;
    letter-spacing: 0.5em;
  }
}

.wrap-text {
  margin-bottom: 96px;
  width: 100vw;
}

.wrap-text-center {
  text-align: center;
  margin-bottom: 96px;
  width: 100vw;
  opacity: 1.0;
  color: #fff;
}

.wrap-button {
  svg {
    cursor: pointer;
  }
}

.wrap-modal-session {
  height: 70vh;
  .modal-form__body {
    height: calc(100% - 100px);
    overflow-y: auto;
  }
  .form-control {
    &:focus {
      box-shadow: none;
      border-color: #000;
    }
    &.form-select[size='2'] {
      height: 365px;
    }
  }
  .list-group {
    border: 1px solid #000;
    padding: 17px 25px;
    height: calc(100% - 170px);
    overflow-y: auto;
    margin-bottom: 16px;
    &-item {
      opacity: 1;
    }
  }
}

.border-dashed {
  border-style: dashed;
}

#loading-screen #loading-cube-wrapper {
  z-index: 1;
}
