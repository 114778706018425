.panorama-side-bar {
  position: absolute;
  top: 94px;
  z-index: 1;
  width: 300px;
  height: calc(100vh - 191px);
  padding: 18px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .list-panorama {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .list-panorama-item {
      padding: 10px 0px;
      cursor: pointer;
    }
    .item-name {
      color: #fff;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}
::-webkit-scrollbar-thumb {
  display: none;
}
#myProgress {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 100;
  height: 10px;
  background-color: black;
  display: none;
}
#myBar {
  position: absolute;
  width: 1%;
  height: 30px;
  height: 10px;
  background-color: grey;
  display: none;
}
