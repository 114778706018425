.close-btn {
  mix-blend-mode: difference;
  filter: invert(1);
}

.modal {
  &-amenity {
    background: #fff;
    padding: 40px 51px;
    margin-left: 150px;
    .modal-header {
      margin-bottom: 33px;
    }
    .f-subtitle {
      font-size: 12px;
      color: #414345;
      font-weight: 300;
      letter-spacing: 0.18em;
    }
    .wrap-img {
      width: 598px;
      height: 338px;
      overflow: hidden;
    }
    .arrow {
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      &-chevron {
        width: 10.5px;
        height: 21px;
        display: inline-block;
      }
      &-prev {
        left: -27px;
      }
      &-next {
        right: -27px;
      }
    }
  }
  &-webgl_hotspot {
    background: #fff;
    padding: 53px 67px;
    width: 90%;
    max-width: 1053px;
    font-size: 20px;
    .border-end {
      border-color: #282A2D!important;
    }
    .modal-header {
      margin-bottom: 33px;
    }
    .wrap-img {
      min-height: 403px;
    }
    .arrow {
      position: absolute;
      bottom: -28.5px;
      &-long {
        width: 62.26px;
        height: 23.49px;
        display: inline-block;
      }
      &-prev {
        left: -45px;
      }
      &-next {
        right: -45px;
      }
    }
  }
}
