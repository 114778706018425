.wrap-executive-summary {
  position: absolute;
  top: 100px;
  left: 0px;
  z-index: 90;
  width: 100vw;
  height: calc(100vh - 150px);

  .container {
    height: 100%;
  }

  .wrap-city-info {
    height: calc(100% - 20px);
    padding: 20px;
    border: 1px solid #c1c1c1;
    background-color: rgba(255, 255, 255, 0.9);
    margin-top: 10px;
  }

  .wrap-site-info {
    height: 100%;
    margin-top: 10px;
  }
}

.container-fluid {
  height: 100%;
}

.wrap-site-info {
  .site-history {
    height: calc(100% - 180px);
    border: 1px solid #c1c1c1;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .wrap-executive-gallery {
    margin-top: 10px;
    height: 150px;
    overflow-y: hidden;

    .executive-gallery-item {
      background-color: rgba(255, 255, 255, 0.9);
      border: 1px solid #c1c1c1;
      height: 100%;
    }
  }
}

.wrap-executive-gallery {
  div {
    height: 100%;
  }

  .executive-gallery-item {
    width: 100%;
    height: 100%;

    .detail {
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: top left;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
