.wrap-bottom-navigation {
  height: 165px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 100;

  // width: 100%;
  // z-index: 100;
  // display: flex;
  // align-items: center;
  // padding: 5px 20px;
  // color: #ffffff;
  // // font-family: 'Roboto', sans-serif;
  // font-size: 14px;
  // letter-spacing: 0.12em;

  // div {
  //   z-index: 1;
  // }

  // &::after {
  //   content: '';
  //   background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  //   height: 170px;
  //   width: 100%;
  //   z-index: 0;
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   pointer-events: none;
  // }

  // .left-group-btn {
  //   flex-grow: 1;
  //   display: flex;
  //   align-items: center;
  //   font-weight: 500;

  //   .btn-item {
  //     padding: 0px 20px;
  //     display: block;
  //     cursor: pointer;
  //     svg {
  //       width: 24px;
  //     }
  //   }
  // }

  // .right-group-btn {
  //   display: flex;
  //   align-items: center;
  //   font-weight: bold;

  //   .btn-item {
  //     padding: 0px 20px;
  //     font-weight: bold;
  //     font-family: sans-serif;
  //     font-size: 13px;
  //     cursor: pointer;
  //     svg {
  //       width: 24px;
  //     }
  //   }
  // }

  .nav-menu {
    ul {
      padding-top: 106px;
    }

    $footer-col-width-px: 75px;
    $footer-col-width-per: 5.49%;

    li {
      flex: 0 0 $footer-col-width-per;
      max-width: $footer-col-width-px;

      @for $i from 2 through 4 {
        &.w-#{$i}x {
          flex-basis: $i * $footer-col-width-per;
          max-width: $i * $footer-col-width-px;
        }
      }
      &.w-auto {
        flex: auto;
        max-width: 100%;
      }
    }
  }

  .f-title {
    font-size: calc(33px + 0.25vw);
    font-weight: 500;
    text-transform: capitalize;
    padding-right: 70px;
  }

  .btn-item {
    cursor: pointer;
    display: inline-block;
    width: 32px;
    height: 32px;
    svg {
      max-width: 100%;
    }

    @media (max-width: 767px) {
      padding: 0.25rem;
    }
  }
}
