.wrap-modal-session {
  height: auto;
  max-height: 703px;
  .modal-form__body {
    height: calc(100% - 100px);
    overflow-y: auto;
    padding-right: 17px;
    margin-right: -17px;
  }
  .form-control {
    &:focus {
      box-shadow: none;
      border-color: #000;
    }
    &.form-select[size="2"] {
      height: 365px;
    }
    &.border-dashed {
      border: 1px dashed #000;
      background: #fff;
    }
    &:disabled {
      background: #F0F0F0;
    }
  }
  .list-group {
    border: 0;
    padding: 0;
    height: 362px;
    overflow-y: auto;
    margin-bottom: 16px;
    padding-right: 17px;
    margin-right: -17px;
    &-item {
      opacity: 1;
      line-height: 3em;
      font-size: 14px;
      font-weight: 400;
      color: #414345;
      padding-left: 10px;
      padding-right: 10px;
      &.active {
        color: #000;
      }
      &:nth-child(2n+1) {
        background: #F0F0F0;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #d2d2c6;
    }
    &::-webkit-scrollbar-track {
      background: #f0f0ea;
    }
  }
}
