.wrap-home-gallery {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #282a2d;
  padding-top: 4px;
  padding-bottom: 80px;
  border-top: 1px solid rgba($color: #ffffff, $alpha: 0.2);

  * {
    font-size: 26px;
    line-height: 1.2em;
    font-family: 'PP Hatton', serif;
    color: #ffffff;

    .h2 {
      color: #ffffff;
    }
  }

  .headertitle {
    top: 10px;
  }

  .home-gallery-header {
    padding-top: 155px;
    h1 {
      margin-bottom: 54px;
      // font-family: 'GT Walsheim Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 115%;
      letter-spacing: 0.075em;
      color: #15131c;
    }
  }

  .container {
    .wrap-image-list {
      margin-top: 10px;
    }
  }

  .headertitle {
    margin-top: 10px;
  }
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    height: 15px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f100;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #88888850;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #55555550;
  }
}
.mb-15 {
  margin-bottom: 15px;
  padding-left: 7.5px !important;
  padding-right: 7.5px !important;
}
.fancybox__slide {
  background: rgba(0, 0, 0, 0.2);
  padding: 0 !important;
  .fancybox__content {
    touch-action: none; /* Disables pinch-zoom */
    user-select: none; /* Prevents text selection */
    -webkit-user-select: none; /* iOS Safari */
    cursor: grab !important;
    width: 100% !important;
    height: 100% !important;
    .fancybox__image {
      object-fit: contain;
      transform: none !important;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.fancybox-wide-img {
  .fancybox__content {
    img {
      object-fit: contain !important;
    }
  }
}

.fancybox-tall-img {
  .fancybox__content {
    img {
      object-fit: contain !important;
    }
  }
}

.fancybox__thumbs {
  display: none;
}
.fancybox__nav {
  display: none;
}
.pure-fancy-box > .fancybox__toolbar {
  display: none !important;
}

.wrap-btn-fancy {
  .btn-prev {
    position: absolute;
    z-index: 1051;
    top: 50%;
    left: 0;
  }
  .btn-next {
    position: absolute;
    z-index: 1051;
    top: 50%;
    right: 0;
  }
  .icon-arrow {
    width: 60px;
    height: 60px;
  }
}
.btn-close-fancy {
  position: absolute;
  z-index: 1051;
  top: 20px;
  right: 25px;
  .icon-close {
    width: 40px;
    height: 40px;
  }
}
.card-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.25rem;

  .card {
    display: inline-block;
    object-fit: cover;
    width: 100%;
  }
}

.media-type-buttons {
  padding: 10px 24px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  flex-direction: row;
  display: inline-flex;
  padding-bottom: 32px;
}

.media-type-button {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 55px;
  gap: 10px;
  width: 152px;
  height: 46px;
  border: 1px solid rgba($color: #ffffff, $alpha: 0.2);
  color: #ffffff;
  font-family: 'Centra No1 TRIAL';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.media-type-button-active {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 55px;
  gap: 10px;
  width: 152px;
  height: 46px;
  border: 1px solid rgba($color: #ffffff, $alpha: 1);
  color: #ffffff;
  font-family: 'Centra No1 TRIAL';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.media-type-button:hover {
  border: 1px solid rgba($color: #ffffff, $alpha: 1);
}

.media-type-button:active {
  border: 1px solid rgba($color: #ffffff, $alpha: 1);
}

.media-type-header {
  text-align: -webkit-center;
}

.headertitle {
  margin-top: 10px;
}

.home-gallery-header {
  .wrap-home-gallery {
    .headertitle {
      background-color: #ff0000;
    }
  }
}

.image-mosaic {
  display: grid;
  gap: 1rem;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
  grid-auto-rows: 240px;
  border-top: 1px solid rgba($color: #ffffff, $alpha: 0.2);
  padding-top: 40px;
  padding-bottom: 20px;

  .h2 {
    color: #ffffff;
  }

  &__title {
    font-size: 26px;
    line-height: 1.2em;
    text-transform: uppercase;
    font-family: 'PP Hatton', serif;
    color: #ffffff;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #353535;
    font-size: 3rem;
    color: #fff;
    box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem, rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    transition: all 500ms;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    background-attachment: fixed;
    object-fit: cover;
    font-family: 'PP Hatton';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 62px;
    /* or 282% */

    text-transform: capitalize;

    color: #ffffff;
  }
}

@media screen and (min-width: 600px) {
  .card-tall {
    grid-row: span 2 / auto;
  }

  .card-wide {
    grid-column: span 2 / auto;
  }

  .card-style1 {
    grid-column: span 1 / auto;
  }

  .card-style2 {
    grid-column: span 2 / auto;
  }

  .card-style3 {
    grid-column: span 3 / auto;
  }

  .card-style4 {
    grid-column: span 4 / auto;
  }

  .card-style5 {
    grid-column: span 5 / auto;
  }

  .card-style6 {
    grid-column: span 6 / auto;
  }

  .card-style7 {
    grid-column: span 7 / auto;
  }

  .card-style8 {
    grid-column: span 8 / auto;
  }
}
