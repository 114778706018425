@import '../../common';

.wrap-post-engagement {
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  .list-group-item {
    font-size: calc(11px + 0.1vw);
    a {
      color: $sidenav-bg;
      &:hover,
      &:focus {
        opacity: 0.75;
      }
    }
  }
  header {
    padding-top: 140px;
  }
  main {
    padding-top: 30px;
  }
}

.fs-xs {
  font-size: calc(8px + 0.1vw);
}

.bg-dark {
  background: $sidenav-bg;
  color: #fff;
}

.col.w-37 {
  width: 37%;
  flex: 0 0 37%;
}
