// .wrap-team {
//     position: absolute;
//     top: 100px;
//     left: 0px;
//     z-index: 90;
//     width: 100vw;
//     height: calc(100vh - 150px);
//     background-color: #4e4d4b;

//     .container {
//         height: 100%;
//     }

//     .wrap-company-info {
//         height: calc(100% - 20px);
//         padding: 20px;
//         border: 1px solid #c1c1c1;
//         background-color: rgba(255, 255, 255, 0.9);
//     }

//     .wrap-team-member {
//         height: 100%;
//     }
// }

// .container-fluid {
//     height: 100%;
// }

// .wrap-team-member {
//     .team-member-summary {
//         height: calc(100% - 190px);
//         border: 1px solid #c1c1c1;
//         padding: 20px;
//         background-color: rgba(255, 255, 255, 0.9);
//     }

//     .wrap-members {
//         height: 150px;
//         overflow-y: hidden;
//         margin-top: 20px;

//         .member-profile {
//             background-color: rgba(255, 255, 255, 0.9);
//             border: 1px solid #c1c1c1;
//             height: 100%;
//         }
//     }
// }

// .wrap-members {
//     div {
//         height: 100%;
//     }

//     .member-profile {
//         width: 100%;
//         height: 100%;

//         .wrap-detail {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             background-repeat: no-repeat;
//             background-size: cover;
//             position: relative;

//             .info {
//                 position: absolute;
//                 display: flex;
//                 flex-direction: column;
//                 width: 100%;
//                 padding: 5px;
//                 justify-content: flex-end;

//                 >span {
//                     &:first-child {
//                         font-size: 16px;
//                         font-weight: bold;
//                     }

//                     &:nth-child(2) {
//                         font-size: 11px;
//                     }
//                 }
//             }
//         }
//     }
// }

.wrap-team {
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 90;
  width: 100vw;
  height: 100vh;
  background-color: #282a2d;
  padding-top: 130px;

  .team-title {
    width: 100%;
    font-size: 1rem;
    padding: 10px 0 0 0;
    margin-bottom: 0px;
    color: #5E5E5E;
  }

  .img-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid rgba($color: #ffffff, $alpha: 0.2);
  }

  .wrap-img {
    min-height: 100px;
    height: 13vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .w-33 {
    width: 33.33%;
  }
}