.wrap-related-asset {
  position: absolute;
  z-index: 90;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 1);
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
