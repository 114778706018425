$cms-page-bg: #f9f9f3;
$cms-input-bg: #f0f0ea;
$cms-page-color: #414345;
$cms-page-footer-height: 85px;
$cms-support-ticket-input: #f0f0ea;

.wrap-cms-page {
  min-height: 100vh;
  background: $cms-page-bg;
  color: $cms-page-color;
  .wrap-top-nav-cms {
    position: sticky;
    background: $cms-page-bg;
    .nav-menu ul li span {
      color: $cms-page-color;
      &::before {
        border-color: $cms-page-color;
      }
    }
    img {
      filter: brightness(0.2);
    }
  }
  .card {
    border-radius: 0px;
  }
  .btn-customer-action {
    text-decoration: underline;
    text-transform: uppercase;
  }

  .container {
    position: relative;
    height: calc(100vh - 165px);
  }

  .btn {
    background: $cms-page-bg;
    color: $cms-page-color;

    &-cancel {
      color: #a3a394;
    }
    &.sort-desc svg {
      transform: rotate(180deg);
    }
    &-more {
      line-height: 6px;
      padding: 0 8px 6px;
      margin-left: 7px;
      background: $cms-input-bg;
      &:hover,
      &:focus {
        box-shadow: none;
      }
    }
  }

  .page-header {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    position: sticky;
    top: 165px;
    z-index: 100;
    background: $cms-page-bg;
    .f-title {
      font-size: 28px;
    }
    &__input-search {
      max-width: 450px;
      .form-control {
        color: #414345;
        border-right: 0;
        font-size: 14px;
        &,
        + .input-group-text {
          background-color: $cms-input-bg;
          border-color: $cms-page-bg;
          border-radius: 0;
        }
        .input-group-text {
          border-left: 0;
        }
        &:focus {
          outline: 0;
          box-shadow: none;
          border-color: transparent;
        }
      }
    }
  }

  .page-body {
    padding-bottom: $cms-page-footer-height;
  }

  .page-footer {
    position: absolute;
    bottom: 0;
    @media only screen and (max-width: 1366px) {
      bottom: 100px;
    }
    height: $cms-page-footer-height;
    background-color: $cms-page-bg;
    left: var(--bs-gutter-x, 0.75rem);
    right: var(--bs-gutter-x, 0.75rem);
    a {
      color: $cms-page-color;
      font-size: 14px;
    }
  }

  .icon-collapse {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-top: 1px solid;
    border-right: 1px solid;
    margin-left: 2px;
    transform: rotate(135deg);
    margin-bottom: 2px;
    margin-left: 2px;
    &.show {
      transform: rotate(-45deg);
      top: 21px;
    }
  }

  $modal-padding-x: 66px;
  .wrap-modal-cms {
    width: 100%;
    max-height: 805px;
    background: #fff;
    box-shadow: none;
    z-index: 102;
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
    &-favorite {
      max-width: 1272px;
      .card-title.f-normal {
        font-size: 14px;
        letter-spacing: 0.2em;
      }
    }

    .modal-header {
      position: sticky;
      background: #fff;
      padding: 50px $modal-padding-x 20px;
      .f-title {
        font-size: 28px;
        line-height: 1;
        margin-bottom: 0;
      }
    }
    .modal-body {
      max-height: calc(100% - 100px);
      overflow-y: scroll;
      padding: 0 $modal-padding-x 25px;
      section {
        margin-top: 25px;
        margin-bottom: 25px;
      }
      .modal-body {
        max-height: calc(100% - 100px);
        overflow-y: scroll;
        padding: 0 66px 25px;
        section {
          margin-top: 25px;
          margin-bottom: 25px;
        }
        .f-title {
          font-size: 18px;
        }
      }
    }
    .modal-footer {
      padding-left: $modal-padding-x;
      padding-right: $modal-padding-x;
      padding-bottom: 25px;
    }

    .table-responsive {
      max-height: 277px;
      height: auto;
      thead {
        background: #fff;
      }
    }
  }

  .wrap-modal-backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(#c4c4c4, 0.5);
    z-index: 101;
    &.hidden {
      visibility: hidden;
      opacity: 0;
    }
  }

  .table {
    color: $cms-page-color;

    &-row-collapse {
      margin-left: -32px;
      th.border-0 {
        width: 32px;
      }
    }

    &-responsive {
      height: calc(100vh - 350px);
      @media only screen and (max-width: 1366px) {
        height: calc(100vh - 450px);
      }
    }
    thead {
      position: sticky;
      top: 0px;
      background: $cms-page-bg;
      z-index: 2;
    }
    th {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 400;
      padding-left: 0.5rem;
      border-style: unset;
      &:not(.border-0) {
        box-shadow: inset 0 1px 0 $cms-page-color, inset 0 -1px 0 $cms-page-color;
      }
      &.editable {
        position: relative;
        cursor: pointer;
      }
      .btn-more {
        position: absolute;
        top: calc(50% - 10px);
        display: none;
      }
      &:hover .btn-more {
        display: inline-block;
      }
    }
    td {
      font-size: 14px;
      border: 0;
    }
    &-striped > tbody > tr:nth-of-type(odd) > td.bg-0 {
      --bs-table-accent-bg: $cms-page-bg;
    }
    &-customer-cms {
      .btn-more {
        background-color: #e4e4da;
      }
      .btn-copy-session-url {
        position: relative;
        .copy-tooltip {
          visibility: hidden;
          background-color: #555;
          color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 5px 20px;
          position: absolute;
          z-index: 1000;
          top: -50px;
          left: -15%;
          font-size: 11px;
          opacity: 0;
          transition: opacity 0.3s;
          white-space: nowrap;
          &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: #555 transparent transparent transparent;
          }
        }
        &:hover {
          .copy-tooltip {
            visibility: visible !important;
            opacity: 1 !important;
          }
        }
      }
    }
  }

  .form-group {
    border-bottom: 1px solid $cms-page-color;
    display: flex;
    align-items: center;
  }
  .form-label {
    width: 100px;
    flex: 0 0 100px;
    margin-bottom: 0;
  }
  .form-control {
    border: 0;
    padding-left: 0;
    padding-right: 0;
    &:focus {
      box-shadow: none;
    }
  }

  .wrapper-scroll-y {
    overflow-y: scroll;
    margin-right: -30px;
    padding-right: 30px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #d2d2c6;
    }
    &::-webkit-scrollbar-track {
      background: #f0f0ea;
    }
  }
  .amenity-wrapper {
    max-height: 212px;
  }
  .table-question {
    th {
      width: 16%;
    }
    tbody {
      margin-top: 10px;
      tr:nth-child(2n) {
        --bs-table-accent-bg: var(--bs-table-striped-bg);
      }
      tr {
        td {
          padding-bottom: 10px;
          padding-top: 10px;
          p:nth-child(2n + 1) {
            font-weight: 500;
          }
          p {
            margin-bottom: 0px;
          }
        }
      }
    }
    .table-striped > tbody > tr:nth-of-type(odd) {
      --bs-table-accent-bg: none;
    }
  }
  .wrap-modal-support-tickets {
    max-width: 1110px;
    width: 100%;
    max-height: 805px;
    background: #fff;
    box-shadow: none;
    z-index: 102;
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
    &-favorite {
      max-width: 1272px;
      .card-title.f-normal {
        font-size: 14px;
        letter-spacing: 0.2em;
      }
    }

    .modal-header {
      position: sticky;
      background: #fff;
      padding: 40px $modal-padding-x 60px;
      display: flex;
      justify-content: space-between;
      .f-title {
        font-size: 28px;
        line-height: 1;
        margin-bottom: 0;
      }
      svg {
        cursor: pointer;
      }
    }
    .modal-body {
      max-height: calc(100% - 100px);
      overflow-y: scroll;
      padding: 0 $modal-padding-x 25px;
      section {
        margin-top: 25px;
        margin-bottom: 25px;
      }
      .modal-body {
        max-height: calc(100% - 100px);
        overflow-y: scroll;
        padding: 0 66px 25px;
        section {
          margin-top: 25px;
          margin-bottom: 25px;
        }
        .f-title {
          font-size: 15px;
        }
      }
      .form-select-sm {
        background: #f0f0ea;
        border-radius: 0px;
        padding: 0px;
        border: 0;
        span {
          background: #f0f0ea;
        }
        .css-1s2u09g-control {
          background: #f0f0ea;
          border: 0px;
        }
        .css-1pahdxg-control {
          box-shadow: unset;
          background: #f0f0ea;
          border: 0px;
          &:hover {
            border-color: #f0f0ea;
          }
        }
      }
      .css-2613gy-menu {
        background: #f0f0ea;
      }
      .form-control {
        background: #f0f0ea;
        border-radius: 0px;
        padding-left: 10px;
      }
    }
    .modal-footer {
      padding-left: $modal-padding-x;
      padding-right: $modal-padding-x;
      padding-bottom: 25px;
      .text-underline {
        text-transform: uppercase;
        color: $cms-page-color;
        font-weight: 400;
      }
    }

    .table-responsive {
      max-height: 277px;
      height: auto;
      thead {
        background: #fff;
      }
    }
  }
  .wrap-modal-confirm-message {
    max-width: 636px;
    width: 100%;
    max-height: 805px;
    background: #fff;
    box-shadow: none;
    z-index: 102;
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
    &-favorite {
      max-width: 1272px;
      .card-title.f-normal {
        font-size: 14px;
        letter-spacing: 0.2em;
      }
    }
    .modal-header {
      position: sticky;
      background: #fff;
      padding: 40px $modal-padding-x 60px;
      display: flex;
      justify-content: space-between;
      .f-title {
        font-size: 28px;
        line-height: 1;
        margin-bottom: 0;
      }
      svg {
        cursor: pointer;
      }
    }
    .modal-body {
      max-height: calc(100% - 100px);
      overflow-y: scroll;
      padding: 0 $modal-padding-x 25px;
      section {
        margin-top: 25px;
        margin-bottom: 25px;
      }
      .modal-body {
        max-height: calc(100% - 100px);
        overflow-y: scroll;
        padding: 0 66px 25px;
      }
    }
  }
}
