.wrap-top-nav-cms {
  height: 165px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 100;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;

  span {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 6px 0;
    cursor: pointer;

    &:hover {
      color: #fff;
    }
  }

  .menu-img {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    overflow: hidden;
    display: inline-block;

    img {
      width: 100%;
    }
  }

  .nav-menu {
    ul {
      padding-top: 16px;

      li {
        &:nth-child(2) {
          transform: translateX(300%);
        }
        &:nth-child(3) {
          transform: translateX(200%);
        }
        &:nth-child(4) {
          transform: translateX(100%);
        }
        &:nth-child(6) {
          transform: translateX(-100%);
        }
        &:nth-child(7) {
          transform: translateX(-200%);
        }
        &:nth-child(8) {
          transform: translateX(-300%);
        }
        &:nth-child(9) {
          transform: translateX(-400%);
        }
        user-select: none;
        flex: 1 0 0%;
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        &:not(:nth-child(5)) {
          opacity: 0;
        }

        span {
          &::before {
            content: '';
            height: 30px;
            border-left: 1px solid #fff;
            position: absolute;
            top: -100%;
            left: 50%;
            transform: translate(-50%, -100%);
            opacity: 0;
            transition: all 0.2s;
          }
        }
      }

      li.active {
        span {
          &::before {
            top: 0;
            opacity: 1;
          }
        }
      }
    }
  }

  &.show {
    .nav-menu {
      ul {
        li {
          opacity: 1;
          transform: translateX(0);
          transition: opacity 0.5s ease-in, transform 0.5s ease-in-out;
          &:hover {
            span {
              &::before {
                top: 0;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

#wrap-cube-model {
  margin-top: 5px;
  display: inline-block;
  cursor: pointer;
}

#cube-model {
  width: 35px;
  height: 35px;
  .animation-drag & {
    animation: cubeDownOnDrag 0.5s 0.02s forwards ease-in-out;
  }
}

