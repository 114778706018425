$font-family-sans-serif: 'Josefin Sans',
sans-serif; // Override mdb.css

$font-main: 'Centra No1 TRIAL',
sans-serif;
$font-title: 'PP Hatton',
sans-serif;

$list-group-bg: transparent;
$list-group-border-color: transparent;
$list-group-item-color-scale: 0.3;
$list-group-hover-bg: transparent;
$list-group-active-bg: transparent;
$list-group-active-border-color: transparent;

$sidenav-bg: rgba(#343434, .9);

// scss-docs-start form-switch-variables
$form-switch-width:               3.9286em;
$form-switch-color:               #D2D2C6;
$form-switch-bg-image:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-12.5 -12.5 25 25'><circle r='12.5' fill='#{$form-switch-color}'/></svg>");
$form-switch-bg-color:            #E4E4DA;
$form-switch-checked-color:       $form-switch-color;
$form-switch-checked-bg-image:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-12.5 -12.5 25 25'><circle r='12.5' fill='#{$form-switch-checked-color}'/></svg>");
// scss-docs-end form-switch-variables

