@import '../../common';

.wrap-sidenav {
  position: absolute;
  z-index: 111;
  width: fit-content;
  height: calc(100vh - 210px);
  @media only screen and (max-width: 1366px) {
    height: calc(100vh - 270px);
  }
  top: 94px;
  left: 0;
  color: #fff;
  .sidenav {
    width: 223px;
  }

  // h2 {
  //     position: relative;
  // }

  // h2::before {
  //     content: '';
  //     position: absolute;
  //     left: -1.25rem;
  //     top: 50%;
  //     margin-top: -2px;
  //     width: 35px;
  //     height: 2px;
  //     background-color: #ffffff;
  // }

  > div {
    height: 100%;
  }

  .sidenav__heading {
    padding: 0;

    h2 {
      padding: 0 .75rem;
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 61px;
      margin-bottom: 10px;
    }
  }

  .sidenav__body {
    justify-content: start;
    line-height: 2.188;
  }

  .sidenav--dark {
    background: $sidenav-bg;

    .list-group-item {
      &:hover {
        opacity: 1;
      }
    }
    .list-group-heading {
      color: #ababab;
    }
  }

  .wrap-btn-detail .list-group .list-group-item,
  .list-group-item {
    padding: 0 .75rem;
  }
  .list-group-item {
    font-weight: 400;
    &.active, &.list-group-heading {
      font-weight: 500;
    }
    &.active {
      opacity: 1;
    }
  }
  .list-group-heading {
    cursor: initial;
    flex-basis: 100%;
    letter-spacing: 0.12em;
  }
  .list-group-horizontal .list-group-item:not(.list-group-heading) {
    flex-basis: 30%;
  }
}

.wrap-sidenav--right {
  left: unset;
  right: 0;
}

.wrap-btn-detail {
  width: 100%;
  text-align: left;
  color: #fff;

  .list-group {
    .list-group-item {
      text-transform: uppercase;
      text-decoration: underline !important;
      font-weight: 400;
      font-size: 14px;
      padding: 1.25rem 2rem;
      cursor: pointer;
      opacity: 1;
    }
  }
}

.wrap-btn-show-filter {
  font-size: 11px;
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2rem;
  z-index: 1120;

  p {
    margin: 0;
    b {
      color: #fff;
      text-decoration: underline;
    }
  }
}

#sidenavDetail {
  .sidenav {
    &__body {
      .list-group-item {
        opacity: 1;
      }
      & > .list-group .list-group-item {
        cursor: initial;
      }
    }
  }
  .sidenav__heading h2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.accordion-filter {
  .list-group-heading {
    font-size: 12px;
    &::after {
      content: '+';
      display: inline-block;
      margin-left: .5rem;
      margin-top: -3px;
    }
    &.open::after {
      content: '-';
    }
  }
}
