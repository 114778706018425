@import './common';

* {
  box-sizing: border-box;
}

html,
body,
#root,
.bg {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}
.wrap-index-page {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
canvas {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.bg,
canvas {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  cursor: grab;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  background-color: #4e4d4b;
  font-family: $font-main;
}

.page {
  background-color: #eee;
  min-height: 100vh;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888850;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #55555550;
}

/* fade in/out animation */
.fade-item-enter {
  opacity: 0;
}

.fade-item-enter-active {
  opacity: 1;
  transition: opacity 125ms ease-in;
}

.fade-item-exit {
  opacity: 1;
}

.fade-item-exit-active {
  opacity: 0;
  transition: opacity 125ms ease-in;
}

/* fade left animation */
.fade-left-enter {
  margin-left: -100%;
  opacity: 0;
}

.fade-left-enter-active {
  margin-left: 0;
  opacity: 1;
  transition: all 500ms linear;
}

.fade-left-exit {
  margin-left: 0;
  opacity: 1;
}

.fade-left-exit-active {
  margin-left: -100%;
  opacity: 0;
  transition: all 500ms linear;
}

/* fade right animation */
.fade-right-enter {
  right: -100%;
  opacity: 0;
}

.fade-right-enter-active {
  right: 0;
  opacity: 1;
  transition: all 500ms linear;
}

.fade-right-exit {
  right: 0;
  opacity: 1;
}

.fade-right-exit-active {
  right: -100%;
  opacity: 0;
  transition: all 500ms linear;
}

.rs-base.hide {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}
