.wrap-gallery-image {
  width: 100%;
  height: 100%;
  position: absolute;
  // z-index: 90;
  top: 0;
  left: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #5b5b5b;

  .slick-slider {
    max-height: 100vh;
    overflow: hidden;

    .gallery-item {
      width: 100vw;
      height: 100vh;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;

      .gallery-hotspot {
        position: absolute;
        z-index: 100;
        cursor: pointer;
        width: 30px;
      }
    }
  }
}

.btn-gallery {
  display: block;
  position: absolute;
  top: 50%;
  cursor: pointer;
  color: #fff;
}

.btn-gallery.prev-slide {
  left: 10vw;
}

.btn-gallery.next-slide {
  right: 10vw;
}
