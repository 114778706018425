//font

/* Josefin Sans */
// @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500&display=swap');
/* Philosopher */
@import url('https://use.typekit.net/xcu1jzs.css');
/* Roboto */
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($path+".eot");
    src: url($path+".eot?#iefix") format("embedded-opentype"), url($path+".woff")
        format("woff"), url($path+".woff2") format("woff2"), url($path+".ttf")
        format("truetype");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

/* ===== Usage ===== */
//Title font
@include font-face(PP Hatton, "./fonts/PPHatton-Medium", 500);
@include font-face(PP Hatton, "./fonts/PPHatton-Bold", 700);
//Main font
@include font-face(Centra No1 TRIAL, "./fonts/CentraNo1TRIAL-Light", 300);
@include font-face(Centra No1 TRIAL, "./fonts/CentraNo1TRIAL-Book", 400);
@include font-face(Centra No1 TRIAL, "./fonts/CentraNo1TRIAL-Medium", 500);
@include font-face(Centra No1 TRIAL, "./fonts/CentraNo1TRIAL-Bold", 700);

@import './common';

@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';

@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/list-group';
@import '../node_modules/bootstrap/scss/utilities';
@import '../node_modules/bootstrap/scss/accordion';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/card';
@import '../node_modules//bootstrap/scss/forms/form-control';
@import '../node_modules/bootstrap/scss/tables';
@import '../node_modules/bootstrap/scss/bootstrap-utilities.scss';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/badge';
@import '~lightgallery/scss/lightgallery.scss';
@import '~lightgallery/scss/lg-zoom.scss';
@import '~lightgallery/scss/lg-thumbnail.scss';
@import "~@fancyapps/ui/dist/fancybox.css";

$utilities: map-get-multiple($utilities,
  ('float',
    'text-transform',
    'display',
    'justify-content',
    'flex-direction',
    'text-align',
    'position',
    'height',
    'padding-y'
  ));
@import '../node_modules/bootstrap/scss/utilities/api';

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

.list-group {
  &-item {
    opacity: $list-group-item-color-scale;
    cursor: pointer;

    &.active {
      font-weight: 500;
      opacity: 1;
    }

    &:hover {
      color: pointer;
    }

    font-weight: 100;
  }

  &-heading {
    text-transform: uppercase;
    opacity: 1;
  }
}

.d-flex-column {
  @extend .d-flex;
  @extend .flex-column;

  &>* {
    flex-shrink: 0;
  }
}

.title,
.f-title,
.card-title {
  font-family: $font-title;
}

.btn-text {
  color: #000000;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #000000;
  }
}

.side-card {
  height: calc(100vh - 240px);
  border: none;
  border-radius: 0;
  position: absolute;
  z-index: 100;
  top: 100px;
  width: 300px;
  padding: 20px;
  background: $sidenav-bg;

  h2 {
    position: relative;
  }

  h2::before {
    content: '';
    position: absolute;
    left: -1.25rem;
    margin-top: -2px;
    top: 50%;
    width: 25px;
    height: 2px;
    background-color: #ffffff;

    @media screen and (max-width: 1025px) {
      width: 15px;
    }
  }

  .card {
    box-shadow: none;
    border: 0px;
    color: #ffffff;
    background: transparent;
  }

  .card-title {
    padding: 0.5rem 1rem;
    color: #fff;
    font-weight: 300;
    font-size: 1.7rem;

    @media screen and (max-width: 1025px) {
      padding: 0.5rem 0.5rem;
    }
  }

  .btn-arcodion {
    cursor: pointer;
  }

  .wrap-btn-detail .list-group .list-group-item,
  .card-header,
  .list-group-item {
    border: none;
    padding: 0 .5rem;
    color: #ffffff;
    background-color: transparent;
    line-height: 2.188;
    &.active {
      font-weight: 400;
      opacity: 1;
    }

    &:hover {
      font-weight: 400;
      opacity: 1;
    }
  }

  .card-body {
    padding: 0;
    height: calc(100% - 52px);
    overflow-y: auto;
    background-color: transparent;

    .acordian {
      background: rgba(0,0,0,0.9);
    }
  }

  &.left-card {
    left: 0;
  }

  &.right-card {
    right: 0;
  }
}

.title-center-bottom {
  position: absolute;
  margin-bottom: 0;
  bottom: 24px;
  left: 50vw;
  transform: translateX(-50%);
  color: #ffffff;
  z-index: 111;
  font-size: 2rem;
}

.invisible {
  opacity: 0;
  pointer-events: none;
}

.b-fade-text {
  * {
    animation: fadeTop forwards;
    opacity: 0;

    &:nth-child(1) {
      animation-duration: 1s;
    }

    &:nth-child(2) {
      animation-duration: 1.2s;
    }

    &:nth-child(3) {
      animation-duration: 1.4s;
    }

    &:nth-child(4) {
      animation-duration: 1.6s;
    }

    &:nth-child(5) {
      animation-duration: 1.8s;
    }

    &:nth-child(6) {
      animation-duration: 2s;
    }
  }
}

@keyframes fadeTop {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.flex-row-fluid {
  flex: 0 1 auto;
}


.nav-menu {
  width: 100%;
  position: relative;
  pointer-events: auto;
  cursor: initial;
  flex-basis: 100%;
  letter-spacing: 0.12em;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    list-style: none;
    padding-left: 0;

    li {
      color: #fff;
      position: relative;
      font-weight: 500;
      font-size: calc(10px + 0.275vw);
    }
  }
}


@mixin ellipsisText($row: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  /*! autoprefixer: ignore next */
  display: -webkit-box;
  /*! autoprefixer: ignore next */
  -webkit-line-clamp: $row;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.text-ellipsis-1line {
  @include ellipsisText();
}

.mt-n2 {
  margin-top: -0.5rem;
}
.my-n2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.my-n1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.shadow-0 {
  box-shadow: none!important;
}

$sizes: 80px, 100px, 125px, 250px;
@each $size in $sizes {
  .w-#{$size} {
    width: $size;
  }
}


.form-switch {
  .form-check-input {
    height: 25px;
    border: 0;
    background-color: $form-switch-bg-color;
    &:checked {
      background-color: #fff;
    }
  }
}

.text-underline {
  text-decoration: underline;
}

.f-normal {
  font-family: 'Centra No1 TRIAL', sans-serif;
}


.slick-dots {
  bottom: 145px; // should be 107px as design
  z-index: 92;

  li {
    margin: 0 2px;

    button:before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 6px;
      border: 1px solid #fff;
      background-color: transparent;
      opacity: 1;
    }

    &.slick-active {
      button:before {
        background-color: #fff;
      }
    }
  }
}
