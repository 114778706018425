.wrap-rs-btn {
  position: absolute;
  z-index: 1000;
  bottom: 60px;
  right: 40px;
}

.btn-rs {
  text-decoration: underline;
  color: #ffffff;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  cursor: pointer;
  font-weight: bold;
}
