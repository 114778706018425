.wrap-search-component {
  position: absolute;
  height: 100vh;
  z-index: 92;
  top: 0;
  left: 0;

  .search-result {
    position: absolute;
    height: calc(100vh - 320px);
    top: 90px;
    width: 300px;
    color: rgba(255, 255, 255, 0.3);

    &.left-card {
      left: 40px;
    }

    .card-title {
      position: sticky;
    }

    .list-group {

      .list-group-item {
        cursor: pointer;

        .wrap-item {
          align-items: center;
        }

        .item-name {
          padding-right: 20px;
          flex-grow: 1;
          overflow: auto;
          color: #ffffff;
        }

        .item-type {
          font-size: 12px;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  .search-input {
    position: absolute;
    bottom: calc(100px + 40px);
    width: 100vw;
    display: flex;
    justify-content: center;
    color: #ffffff;

    .input-group {
      width: calc(100% - 80px);
      position: relative;

      .form-control {
        border-radius: 0;
        width: 100%;
        height: 60px;
        padding-left: 20px;
        padding-right: 40px;
        color: #fff;
        background-color: rgba(52, 52, 52, 0.9);
        border: none;
        font-weight: 200;
      }

      .form-control:focus {
        border: none;
        box-shadow: none;
      }

      .form-control::placeholder {
        color: rgba(255, 255, 255, 0.3);
      }

      .search-icon {
        width: 20px;
        position: absolute;
        right: 20px;
        top: 14px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.3);
      }
    }
  }
}
