.wrap-holding-page {
  color: #fff;

  #loading-cube-wrapper {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }

  #wrap-cube-model {
    position: absolute;
    top: 29px;
    z-index: 1;

    &.disable-click {
      pointer-events: none;
    }
  }

  .intro-content {
    line-height: 1;
    h1 {
      font-size: calc(3rem + 1.25vw);
      font-family: 'Philosopher', serif;
      margin: 2rem 0 1.75rem;
    }
    p {
      font-size: calc(0.875rem + 0.25vw);
      font-weight: 400;
      margin-bottom: 1.5rem;
      letter-spacing: .5em;
    }
  }

}
